body {
  min-height: 100vh !important;
  height: 100vh !important;
}

footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}

* {
  font-family: "Open Sans", sans-serif;
}

.section-body {
  padding: 30px 0;
  padding-bottom: 90px;
}

a.btn {
  margin: 0 5px;
}

.btn {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  border: 0;
  padding: 10px 20px;
}

.btn:hover, .btn:focus, .btn:active {
  border: 0 !important;
  color: white !important;
}

.btn-default {
  background-color: #e6e6e6;
}

.btn-success {
  background-color: #50be00;
}

.btn-success:hover {
  background-color: #3b8b00;
}

.btn-icon {
  padding: 0;
  box-shadow: none;
  border: none;
  background: none;
  width: 64px;
  height: 64px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-icon:hover svg {
  fill: #3b8b00;
}

.btn-icon svg {
  width: 55px;
  height: 55px;
  fill: #50be00;
}

.btn-subtitle {
  display: block;
  font-size: 12px;
  width: 100%;
  text-align: center;
  color: #50be00;
}

.controls {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.controls__col {
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .controls__col--flex {
    display: flex;
    align-items: center;
  }
  .controls__col--flex > * {
    margin-left: 24px;
  }
  .controls__col--flex > *:first-child {
    margin-left: 0;
  }
}

.controls-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.controls-items__item {
  display: inline-block;
  padding: 0 12px;
  border-left: 1px solid #e6e6e6;
}

.controls-items__item:first-child {
  padding-left: 0;
  border-left: none;
}

.input-search {
  position: relative;
}

@media (min-width: 768px) {
  .input-search {
    width: 215px;
  }
}

.input-search:after {
  font-family: 'FontAwesome';
  content: '\f002';
  position: absolute;
  top: 50%;
  font-size: 18px;
  color: #767676;
  transform: translateY(-50%);
  right: 8px;
}

.input-search input {
  background: none;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid #ccc;
  padding-left: 8px;
  padding-right: 28px;
  font-size: 15px;
  color: #767676;
  height: 32px;
  width: 100%;
}

.input-search input:focus {
  outline: none !important;
  border-bottom-color: #50be00;
}

.dataTable thead .sorting:after {
  display: none !important;
}

.dataTable thead > tr th {
  padding: 3px !important;
  font-size: 10px !important;
}

.dataTable thead > tr th.fit-to-content {
  width: 90px !important;
}

.dataTable thead > tr th.w50 {
  width: 50px !important;
}

.dataTable thead > tr th.w60 {
  width: 60px !important;
}

.dataTable thead > tr th.w70 {
  width: 70px !important;
}

.dataTable thead > tr th.w80 {
  width: 80px !important;
}

.dataTable thead > tr th.w90 {
  width: 90px !important;
}

.dataTable thead > tr th.w100 {
  width: 100px !important;
}

.dataTable thead > tr th.w110 {
  width: 110px !important;
}

table.dataTable thead > tr th input, table.dataTable thead > tr th select {
  max-width: 95% !important;
}

table.dataTable thead th input::placeholder {
  color: #a7a7a7;
}

.dataTables_info {
  float: left;
  font-size: 12px;
  color: #000000;
  font-weight: 400;
}

.dataTables_length {
  float: left;
  margin-left: 25px;
}

.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before {
  position: relative !important;
  float: left;
  margin-right: 7px;
  background-color: #7a1815 !important;
  top: 50% !important;
  left: 0 !important;
}

.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td {
  word-break: break-all;
  word-wrap: break-word;
  word-spacing: normal;
  text-overflow: ellipsis;
  white-space: normal;
}

.dataTable tbody td {
  word-break: break-all !important;
}

table.dataTable {
  border: none;
  border-bottom: 1px solid #F90000;
  table-layout: fixed;
  min-width: 700px;
}

table.dataTable input {
  border: 1px solid #d2d6de;
  border-radius: 0;
  padding: 6px 12px;
  height: 34px;
}

table.dataTable th, table.dataTable td {
  border-left: 0 !important;
  border-right: 0 !important;
  max-width: 180px;
  min-width: 65px;
}

table.dataTable thead .sorting {
  padding: 5px !important;
}

table.dataTable thead tr:first-child > th {
  border-bottom: 2px solid #F0F0F0 !important;
}

table.dataTable tfoot {
  display: none;
}

table.dataTable th {
  font-size: 12px;
  color: #767676;
  font-weight: 400;
}

table.dataTable td {
  padding: 8px !important;
  text-align: left;
  vertical-align: middle;
  text-overflow: ellipsis;
  word-break: break-word;
}

table.dataTable td[class*="sorting_"] {
  color: #000000;
}

table.dataTable tr {
  transition: all 0.5s;
}

table.dataTable tr:hover {
  background: #fafafa;
  cursor: pointer;
}

table.dataTable tbody tr.selected {
  background: #F0F0F0;
}

table.dataTable tbody tr.selected [class*="sorting_"] {
  background-color: #f0F0F0;
}

table.dataTable tbody tr.selected:hover [class*="sorting_"], table.dataTable tbody tr.selected:hover {
  background-color: #f0F0F0 !important;
}

table.dataTable tbody tr.selected.error-row, table.dataTable tbody tr.selected.error-row:hover {
  background-color: #C90000 !important;
}

table.dataTable tbody tr.selected.error-row td, table.dataTable tbody tr.selected.error-row [class*="sorting_"], table.dataTable tbody tr.selected.error-row:hover td, table.dataTable tbody tr.selected.error-row:hover [class*="sorting_"] {
  background-color: #C90000 !important;
  color: white !important;
}

table.dataTable + .bottom {
  margin: 0 auto;
  max-width: 1500px;
}

tr {
  transition: background-color 0.5s;
}

tr td {
  transition: color 0.5s;
}

tr.error-row, tr.error-row:hover {
  background-color: #F90000 !important;
}

tr.error-row td, tr.error-row [class*="sorting_"], tr.error-row:hover td, tr.error-row:hover [class*="sorting_"] {
  background-color: #F90000 !important;
  color: white !important;
}

table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc {
  background: none !important;
}

footer {
  background: linear-gradient(to right, #7a1815 0%, #502828 100%);
  color: white;
  font-size: 15px;
  line-height: 22px;
  padding: 30px 0;
}

.navbar {
  background-color: #333 !important;
}

.navbar-header {
  padding-left: 0;
}

.navbar-brand {
  padding: 0;
  margin-left: 0 !important;
}

.navbar-brand .logo {
  background: transparent;
  padding: 0;
  width: auto;
  height: 50px;
}

@media (min-width: 768px) {
  .navbar-brand .logo {
    height: 54px;
  }
}

@media (max-width: 767px) {
  .navbar-nav {
    margin: 0 -15px;
  }
}

.navbar-nav > li > a {
  padding: 20px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
  transition: background-color 0.5s;
}

@media (max-width: 767px) {
  .navbar-nav > li > a {
    padding-left: 15px;
  }
}

.navbar-nav > li > a:hover {
  background-color: #f90000 !important;
}

.navbar-collapse {
  border-top: 0;
  box-shadow: none;
}

.breadcrumbs {
  background: #f0f0f0;
}

.breadcrumb {
  margin-bottom: 0;
  background: transparent;
  padding-left: 0;
}

.breadcrumb li > a {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
}

.breadcrumb li, .breadcrumb li:before {
  font-size: 12px;
  color: #999;
}

.breadcrumb li:before {
  content: '>';
}

.breadcrumb li:first-child:before {
  content: '';
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-menu {
  min-width: 100%;
  padding: 0;
}

.dropdown-menu > li {
  position: relative;
}

.dropdown-menu a {
  text-transform: capitalize;
}

.dropdown-menu li a {
  display: block;
  padding: 5px 20px;
}

.dropdown-menu li a:hover + ul.dropdown-menu {
  display: block;
}

.dropdown-menu li a + ul.dropdown-menu {
  top: 0;
  left: 100%;
}

ul.dropdown-menu:hover {
  display: block;
}

.gp-icon {
  width: 45px;
  height: 45px;
  display: block;
  margin: 0 auto 15px;
  background-size: 100% 100%;
}

.gp-renew {
  background-image: url("../svg/icons/renew.svg");
}

.gp-unrecognized-import {
  background-image: url("../svg/icons/unrecognized-import.svg");
}

.gp-delta-ipma-management {
  background-image: url("../svg/icons/delta_ipma_management.svg");
}

.gp-organisation {
  background-image: url("../svg/icons/organisation.svg");
}

.gp-annual-reporting {
  background-image: url("../svg/icons/annual-report.svg");
}

.gp-agreement-management {
  background-image: url("../svg/icons/agreement_management.svg");
}

.gp-member-assosation-management {
  background-image: url("../svg/icons/member_assosation_management.svg");
}

.gp-certification-body-management {
  background-image: url("../svg/icons/certification_body_management.svg");
}

.gp-my-certification-body {
  background-image: url("../svg/icons/my_certification_body.svg");
}

.gp-country-list-management {
  background-image: url("../svg/icons/country_list_management.svg");
}

.gp-my-member-association {
  background-image: url("../svg/icons/my_member_association.svg");
}

.gp-forecast-management {
  background-image: url("../svg/icons/forecast_management.svg");
}

.gp-query-database {
  background-image: url("../svg/icons/query_database.svg");
}

.gp-invoice-management {
  background-image: url("../svg/icons/invoice_management.svg");
}

.gp-suspend-a-certification-body {
  background-image: url("../svg/icons/suspend_a_certification_body.svg");
}

.gp-level-domain-management {
  background-image: url("../svg/icons/level_domain_management.svg");
}

.gp-users-management {
  background-image: url("../svg/icons/users_management.svg");
}

.gp-manage-certificate-holders {
  background-image: url("../svg/icons/manage_certificate_holders.svg");
}

.gp-view-certificates {
  background-image: url("../svg/icons/view_certificates.svg");
}

.info-icon {
  display: block;
  width: 17px;
  height: 17px;
  background-image: url("../img/info.svg");
  background-repeat: no-repeat;
}

.pdf-reject {
  background-image: url("../img/pdf-reject.png");
}

.pdf-download {
  background-image: url("../img/pdf-download.png");
}

.zoom {
  background-image: url("../img/zoom.png");
}

.settings {
  background-image: url("../img/settings.png");
}

.comment-on {
  background-image: url("../img/comment-on.png");
}

.comment-off {
  background-image: url("../img/comment-off.png");
}

.edit-pencil {
  background-image: url("../img/edit-pencil.png");
}

.trash {
  background-image: url("../img/kosz.svg");
}

.renew {
  background-image: url("../img/renew.svg");
}

.label {
  background: transparent !important;
  border-radius: 0 !important;
  padding: 1px 5px;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
}

.label-danger {
  border: 1px solid #f90000;
  color: #f90000 !important;
}

.label-success {
  border: 1px solid #50be00;
  color: #50be00 !important;
}

.label-warning {
  border: 1px solid #f9b000;
  color: #f9b000 !important;
}

.reporting-header {
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
}

.custom-icon {
  background-size: 100% 100%;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  display: block;
  float: left;
  margin-left: 10px;
}

.custom-comment-on {
  width: 35px !important;
  height: 35px !important;
}

.center-icon {
  float: none;
  margin: 0 auto;
  margin-top: 20px;
}

.cb-name {
  font-weight: 400;
  color: #000000;
  font-size: 15px;
}

.select2-container {
  width: 100% !important;
}

.table-filters {
  border-radius: 5px;
  background-color: #f0f0f0;
  margin: 0;
}

.table-filters .d-flex {
  display: flex;
}

.table-filters .custom-icon {
  width: 20px;
  height: 20px;
  float: unset;
  margin: 0;
}

.table-filters .col-sm-4 {
  padding-right: 0;
}

.table-filters .col-sm-4:not(:first-of-type) {
  padding-left: 0;
}

.no-padding {
  padding-right: 0 !important;
}

.table-filters .input-group-addon {
  padding: 0;
}

.table-filters .form-control, .table-filters .input-group-addon, .table-filters .select2-container--default, .table-filters .select2-selection--single {
  border: 0;
  background: transparent;
}

.table-center td, .table-center th {
  vertical-align: middle;
  text-align: center !important;
}

.dataTable.w-100 {
  width: 100% !important;
}

.dataTables_wrapper {
  overflow-x: scroll;
}

tbody td {
  font-size: 12px;
}

.form-group {
  position: relative;
}

.pointer:hover {
  cursor: pointer;
}

.disabled-mouse:hover {
  cursor: not-allowed;
}

.editable-field {
  float: left;
  padding: 0 5px;
}

.middle-position {
  display: flex;
  justify-content: center;
  align-items: center;
}

.h34 {
  height: 34px;
}

[class*="sorting_"] {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  background-color: #f0f0f0;
}

.dataTables_wrapper .dataTables_paginate {
  width: 100%;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:not(.previous):not(.next) > a {
  border-radius: 4px;
  height: 30px;
  color: #f90000;
  font-weight: 400;
  border: none !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  background: red;
}

.dataTables_wrapper .dataTables_paginate .previous > a, .dataTables_wrapper .dataTables_paginate .next > a {
  font-size: 12px;
  border: 0;
  background: transparent;
  color: #f90000;
}

.dataTables_wrapper .dataTables_paginate .disabled > a {
  color: #cccccc;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  border: none !important;
  border-radius: 4px !important;
  background: transparent;
  color: #f90000 !important;
  transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white;
  background: #f90000;
}

.dataTables_wrapper .dataTables_paginate .paginate_button .active > a, .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: #f90000;
  color: #ffffff !important;
  font-weight: 700 !important;
}

.dataTables_wrapper .dataTables_paginate > a:hover {
  background: transparent;
  border: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  float: left;
}

.annual-report-table th {
  background-color: #fafafa;
}

.report-subtitles {
  font-size: 12px;
  color: #767676;
}

.report-subtitles > div {
  padding: 0 5px;
}

.report-subtitles > div + div::before {
  content: '|';
  position: absolute;
  left: -4px;
  font-size: 15px;
  line-height: 40%;
  color: #e6e6e6;
}

.report-question {
  font-size: 20px;
}

.report-subtitle {
  border-bottom: 2px solid #F90000;
  padding-left: 5px;
  letter-spacing: 0;
  font-size: 10px;
  white-space: nowrap;
}

.report-subtitles .report-subtitle {
  padding-right: 10px;
}

.report-subtitles .report-subtitle.last-child {
  padding-right: 0;
}

.download-anchor {
  color: #f90000;
}

.download-anchor:hover {
  color: #ff0000;
}

.report-table {
  color: #000000;
}

.report-table > .row:nth-child(n + 2) {
  border-top: 2px solid #c0c0c0;
  margin: 45px 0;
}

.green-text {
  color: #50be00;
}

.red-text {
  color: #f90000;
}

.big-icon {
  font-size: 25px;
}

.form-control {
  border: 0;
  border-bottom: 1px solid #767676;
}

.header-margin {
  margin-top: 20px;
  margin-bottom: 10px;
}

.box {
  border-top: 0;
}

.info-box-icon {
  line-height: 70px;
  color: #3e3e3e;
}

.info-box-icon:hover {
  color: #6e6e6e;
}

.info-box-caption {
  line-height: 1 !important;
  font-size: 14px;
  letter-spacing: 0;
  margin: -15px auto;
}

.info-box-icon.bg-transparent {
  background: transparent;
}

.info-box-icon.bg-white {
  background: white;
}

[id*="STABLE"], [id*="VERY"], [id*="LESS"], [id*="UNSTABLE"] {
  color: white;
  transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  font-weight: 700;
}

.select2-results__option[id*="STABLE"], .select2-selection__rendered[title*="STABLE"], .select2-results__option.success {
  background: #00A900;
}

.select2-results__option[id*="VERY"], .select2-selection__rendered[title*="VERY"], .select2-results__option.much-success {
  background: #00F900;
  color: #3f3f3f;
}

.select2-results__option[id*="LESS"], .select2-selection__rendered[title*="LESS"], .select2-results__option.warning {
  background: #E9A900;
}

.select2-results__option[id*="UNSTABLE"], .select2-selection__rendered[title*="UNSTABLE"], .select2-results__option.danger {
  background: #F90000;
}

.select2-selection__rendered[title*="STABLE"], .select2-selection__rendered[title*="VERY"], .select2-selection__rendered[title*="LESS"], .select2-selection__rendered[title*="UNSTABLE"] {
  padding: 0 !important;
}

.select2-results__option.success, .select2-results__option.much-success, .select2-results__option.warning, .select2-results__option.danger {
  color: white;
}

.select2-results .select2-results__option {
  font-size: 12px;
}

.address-box {
  border: 1px solid #afafaf;
  margin: 10px 0;
}

.datepicker-dropdown {
  top: 70px !important;
  left: 10px !important;
  z-index: 99999999;
  width: 200px !important;
  min-width: 200px !important;
}

.modal.modal-wide .modal-dialog {
  width: 90%;
  margin: 0 auto;
}

.modal-body {
  word-wrap: break-word;
}

.modal-wide .modal-body {
  overflow-y: auto;
}

#invoice-certificates-table_wrapper {
  width: 98%;
  margin: 0 auto;
}

.table-input {
  width: 100%;
  min-width: 55px;
}

.select2-results__option[aria-disabled="true"] {
  display: none;
}

.user-image {
  max-width: 150px;
  max-height: 150px;
  margin: 0 auto;
}

.image-label {
  margin: 0 auto;
  float: none;
  text-align: center;
  display: block;
  width: 150px;
}

.form-group.error label {
  color: red;
}

.form-group.error input {
  border-bottom: 1px solid red;
}

.form-group .form-error {
  font-size: 10px;
  color: red;
}

.form-group .form-checkbox {
  border: none;
  background: none;
}

.modal-error-box {
  padding: 3px 3px;
  font-size: 10px;
  color: red;
}

.select2-selection.select2-selection--single {
  max-width: 95%;
}

.select2-results__option {
  /* background: white; */
}

.select2-dropdown, .select2-results {
  mix-width: 100px;
}

.float-none {
  float: none;
  display: inline-block;
}

.border-primary {
  padding: 10px;
}

.cb-holder {
  margin: 10px 0;
  padding: 5px;
}

.remove-cb-button .remove-button {
  border: none;
  background: none;
  border: 1px solid #f90000;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  background-color: #f90000;
  padding: 3px 8px;
}

.custom-selector-container {
  padding-top: 11px;
  padding-bottom: 16px;
  border: 1px solid #afafaf;
  margin: 10px 0;
}

.custom-selector-container .add-cb-button {
  padding: 0 5px;
  margin: 10px 0;
}

.custom-selector-container .add-cb-button .custom-selector {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.custom-selector-container .add-cb-button .custom-add-button {
  padding-left: 35px !important;
  padding-right: 0 !important;
}

.custom-selector-container .add-cb-button .add-button {
  border: none;
  background: none;
  border: 1px solid #50be00;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  background-color: #50be00;
  padding: 5px 15px;
}

.custom-body-name {
  margin: 5px 0;
}

.gp-organisation {
  margin-bottom: 25px !important;
}

.gp-view-certificates, .gp-invoice-management {
  margin-bottom: 20px !important;
  margin-top: 5px !important;
}

.gp-member-assosation-management, .gp-certification-body-management {
  margin-bottom: 20px !important;
}

.report-table table {
  border-collapse: collapse;
  table-layout: fixed;
  max-width: 100%;
  word-wrap: break-word;
}

.report-table .col-sm-10 > table {
  width: 100%;
}

.report-table table, .report-table td, .report-table th, .report-table tr {
  background: transparent !important;
  border: 0;
}

.report-table th {
  border-bottom: 2px solid #F90000;
  padding-left: 5px;
  letter-spacing: 0;
  font-size: 10px;
  white-space: nowrap;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  text-transform: capitalize;
}

.report-table td {
  padding: 5px 10px !important;
}

.report-table .form-control {
  border: 1px solid #afafaf;
  background: #fafafa;
}

table.dataTable.nowrap th {
  white-space: normal !important;
  word-break: break-word;
  text-align: center;
}

table.dataTable.nowrap td {
  white-space: normal !important;
  word-break: break-word;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  color: #a7a7a7;
}

.cvmb-form-table .editor-holder {
  padding-top: 30px;
  margin-top: 20px;
  padding-right: 0;
  margin-right: 0px;
  max-width: 360px;
}

.cvmb-form-table .col-sm-8 > table {
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
}

.annual-report-status-label {
  text-transform: uppercase;
  border: 1px solid;
  margin-right: 40px;
  padding: 1px 3px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
}

.annual-report-status-label.much-success {
  border-color: limegreen;
  color: limegreen;
}

.annual-report-status-label.success {
  border-color: green;
  color: green;
}

.annual-report-status-label.warning {
  border-color: #f9b000;
  color: #f9b000;
}

.annual-report-status-label.danger {
  border-color: #F90000;
  color: #F90000;
}

div.dataTables_wrapper div.dataTables_processing {
  position: fixed !important;
  color: #F90000 !important;
  font-size: 1.5em !important;
  font-weight: bold;
  z-index: 99999;
}

.form-group .custom-label {
  margin-bottom: 25px;
}

.in-button-icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-left: 0px;
}

span.asterisk {
  color: red;
}

.rotated-caret {
  transform: rotate(-90deg);
  margin-left: 5px;
}

.custom-row {
  margin: 25px 0;
}

.logo {
  max-width: 200px;
}

.custom-form-group {
  padding-right: 25px;
}

.custom-form-group-second {
  padding-right: 20px;
}

.custom-form-group-adress {
  padding-right: 20px;
}

input.custom-input {
  color: #000000 !important;
}

.invoice-form-margin {
  margin-right: 2.5%;
}

.cb-custom-heading {
  font-weight: 600;
}

.cb-custom-gap {
  margin: 10px 0;
}

.editor-holder > h4 {
  color: #444;
}

.tooltip-handler {
  padding: 10px;
}

.report-table table h4 {
  font-style: italic;
  font-size: 14px !important;
}

.report-table table ul > li {
  font-style: italic;
  font-size: 12px !important;
}

.report-table table tbody tr:first-child td:first-child {
  font-size: 15px;
  font-weight: 600;
}

div.static-question-wrapper {
  margin: 40px 0;
}

div.static-question-wrapper .row .col-sm-2 h4 {
  color: #444;
  border-bottom: 2px solid #F90000;
  padding-bottom: 5px;
}

div.static-question-wrapper .static-question-header {
  margin: 25px 0;
}

div.cb-domain-wrapper {
  margin: 10px 0 !important;
}

div.cb-domain-wrapper h4.cb-domain-header {
  color: #444 !important;
  border-bottom: 2px solid #F90000;
  font-size: 15px !important;
  padding-bottom: 5px;
}

table.static-question-table {
  width: 80%;
  border: 0;
}

table.static-question-table th {
  border-bottom: 2px solid #F90000;
  text-align: center;
  font-size: 10px;
}

table.static-question-table th p {
  font-size: 10px;
  text-align: center;
}

table.static-question-table tbody tr td {
  text-align: center;
}

table.static-question-table tbody tr td p {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding: 5px;
}

table.static-question-table table {
  width: 100%;
  border: 0;
}

table.static-question-table table tr td {
  text-align: center;
  font-weight: 500 !important;
}

table.table-bottom tbody tr td {
  padding: 10px;
}

table.table-bottom tbody tr td:nth-child(1) {
  font-weight: 600;
}

.loading {
  display: none;
  margin: 0 auto;
  padding-left: 7px;
  margin-left: 7px;
  border: 3px solid rgba(255, 255, 255, 0.7);
  transform: translateZ(0);
  animation: kf-loading 1.1s linear infinite;
  border-left-color: green;
  float: right;
}

.loading.is-active {
  display: block;
}

.loading, .loading::after {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

@keyframes kf-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.select2-selection__clear {
  position: absolute !important;
  right: -10px;
  font-size: 23px;
  color: red;
}

.select2-selection__arrow {
  right: 15px !important;
}

.icheckbox_minimal-red[aria-disabled="false"] {
  border: 1px solid #767676;
}

.icheckbox_minimal-red[aria-disabled="false"]:hover {
  border: unset;
}

.icheckbox_minimal-red[aria-disabled="true"]:hover {
  cursor: not-allowed;
}

.icheckbox_minimal-red.checked.disabled {
  background-position: -40px !important;
}

.static-question-wrapper .icheckbox_minimal-red {
  float: right !important;
}

.static-question-wrapper label {
  max-width: 100px !important;
}

.static-question-wrapper .col-sm-2 .row {
  padding-top: 5px;
}

h4.box-header {
  position: relative;
  font-size: 25px;
  font-weight: bold;
}

.answer-box .box-header + .col-sm-12 {
  padding-left: 50px;
}

.group-status-select + .select2-container {
  margin-top: 10px;
}

#invoice-available-certificates-table, #invoice-certificates-table, #invoice-certificates-table-info {
  min-width: 90% !important;
}

.text-center-important {
  text-align: center !important;
}

.checkbox-center {
  display: block;
  margin: 0 auto;
  float: none;
}

.file-row + .hide-when-files-exists {
  display: none;
}

.files-holder {
  padding-top: 20px;
}

.invoice-delete {
  padding-top: 10px;
  display: block;
  width: 50px;
  margin: 0 auto;
}

.invoice-summary-button {
  display: block;
}

.test-info {
  text-align: center;
  padding: 10px;
  background: red;
}

.test-info-text {
  font-size: 18px;
  font-weight: 800;
  color: white;
}
